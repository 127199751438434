@import "../../../../variables.scss";

.section {
  &-ecosystem {
    padding: 24px 20px;
    overflow: hidden;

    img.ecosystem {
      width: 130%;
      margin-left: -15%;
    }

    .features {
      max-width: 1024px;
      margin: 0 auto;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      column-gap: 20px;
      row-gap: 20px;

      .feature {
        align-items: center;
        border-radius: 5px;
        box-sizing: border-box;
        color: #fff;
        display: flex;
        flex: 1 1;
        flex-direction: column;
        row-gap: 0;
        text-transform: uppercase;
        background: $light-gray-background-color;

        &.video:hover {
          cursor: pointer;
        }

        img {
          border-radius: 8px;
          height: 250px;
          object-fit: cover;
          aspect-ratio: 1;

          &.extra-padding {
            padding: 24px;
          }
        }

        h3 {
          border-radius: 8px;
          font-size: 1.2em;
          font-weight: 700;
          line-height: 150%;
          margin-bottom: 0;
          margin-top: -8px;
          padding: 16px;
          width: 100%;
          background-color: $secondary-background-color;
        }

        div {
          margin: 6px;
        }

        &.pdf {
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .section {
    &-ecosystem {
      padding: 32px 64px;

      img.ecosystem {
        width: 100%;
        margin-left: 0;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .section {
    &-ecosystem {
      .features {
        .feature {
          flex-basis: 100%;
        }
      }
    }
  }
}
