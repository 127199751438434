@import "../../../../variables.scss";

.section {
  &-intro {
    height: 350px;
    position: relative;
    background: url("../../../../../public/assets/Ed blonk website video graphic.png") center no-repeat;
    background-size: contain;

    &:hover {
      cursor: pointer;
    }
  }
}

@media only screen and (min-width: 768px) {
  .section {
    &-intro {
      .caption {
        font-size: 22px;
      }
    }
  }
}

@media only screen and (min-width: 600px) and (max-width: 800px) {
  .section {
    &-intro {
      .portrait {
        width: 40vw;
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .section {
    &-intro {
      .portrait,
      .caption {
        left: 64px;
      }
    }
  }
}

@media only screen and (min-width: 800px) and (max-width: 1440px) {
  .section {
    &-intro {
      .portrait {
        width: 32vw;
      }
    }
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1600px) {
  .section {
    &-intro {
      .portrait {
        width: 30vw;
      }
    }
  }
}

@media only screen and (min-width: 1600px) and (max-width: 1819px) {
  .section {
    &-intro {
      .portrait {
        width: 25vw;
      }
    }
  }
}

@media only screen and (min-width: 1820px) and (max-width: 2000px) {
  .section {
    &-intro {
      .portrait {
        width: 20vw;
      }
    }
  }
}

@media only screen and (min-width: 2001px) and (max-width: 2500px) {
  .section {
    &-intro {
      .portrait {
        width: 15vw;
      }
    }
  }
}

@media only screen and (min-width: 2501px) and (max-width: 3000px) {
  .section {
    &-intro {
      .portrait {
        width: 12vw;
      }
    }
  }
}

@media only screen and (min-width: 3001px) {
  .section {
    &-intro {
      .portrait {
        width: 10vw;
      }
    }
  }
}
